const html = document.documentElement;
const body = document.body;
const scrollBound = document.querySelector(".scroll-bound");
const header = document.querySelector("header");
const headerLogoCompanyName = header.querySelector(
  ".header-logo-svg #Gekkard-text"
);
const HEADER_LIGHT_CLASS = "header--light";

//** header on scroll
function changeHeaderClassOnScroll() {
  let headerHeight = header.offsetHeight;
  let tresshold = headerHeight * 1.5;
  if (
    document.body.scrollTop > tresshold ||
    document.documentElement.scrollTop > tresshold
  ) {
    header.classList.add(HEADER_LIGHT_CLASS);
    headerLogoCompanyName.setAttribute("fill", "var(--logo-dark-color)");
  } else {
    header.classList.remove(HEADER_LIGHT_CLASS);
    headerLogoCompanyName.setAttribute("fill", "var(--logo-light-color)");
  }
}

//** banner phone drop animation
const bannerPhone = document.querySelector(".js-banner-phone");
const banner = document.querySelector(".banner");
const controller = new ScrollMagic.Controller({ vertical: true });

function phoneDropAnimation() {
  let bannerPhoneHeight = bannerPhone.offsetHeight;

  let phoneTween = gsap.timeline().add(
    TweenMax.fromTo(
      bannerPhone,
      { top: -bannerPhoneHeight },
      {
        top: bannerPhoneHeight / 35,
        duration: 0.8,
        onComplete: function() {
          bannerPhone.classList.remove("js-banner-phone");
        }
      }
    )
  );

  let scene = new ScrollMagic.Scene({
    offset: 0,
    triggerElement: banner
  })
    .setTween(phoneTween)
    .addTo(controller)
    .on("leave", function(event) {
      scene.destroy();
      scene = null;
      phoneTween = null;
    });
}

//** hero animation on scroll
const imgArray = [...document.querySelectorAll(".js-hero-img")];
const heroImgAnim = document.getElementById("heroImgAnim");
const frameCount = 50;
const currentFrame = index => `gp_${index.toString().padStart(5, "0")}`;

const updateImage = index => {
  let selec = currentFrame(index + 1);

  if (document.querySelector(`.${selec}`) == null) return false;

  let f = document.querySelector(`.${selec}`);

  imgArray.map(item => {
    item.classList.remove("show");
  });
  if (f.classList.contains("hide")) {
    f.classList.remove("hide");
  }
  f.classList.add("show");
};

function heroAnimationOnScroll() {
  const scrollTop = body.scrollTop || document.scrollTop;
  const maxScrollTop = scrollBound.offsetHeight / 2;
  const scrollFraction = scrollTop / maxScrollTop;
  if (scrollTop > maxScrollTop) return false;

  const frameIndex = Math.min(
    frameCount - 1,
    Math.ceil(scrollFraction * frameCount)
  );

  updateImage(frameIndex + 1);
}

//** map points animation

const mapPointsArea = document.getElementById("map-points");
const pointsArray = [...mapPointsArea.querySelectorAll(".point")];

function setMapPointsAnimation() {
  const pointTooltips = () => {
    let pointIDs = [];
    let transactionIDsArray = [];

    pointsArray.map((point, index) => {
      pointIDs.push(`#${point.id}`);
      transactionIDsArray.push(`#transaction-${point.id}`);
    });

    let tl = gsap.timeline();
    let targets = transactionIDsArray;
    let numberOfTargets = targets.length;

    let duration = 0.5;
    let pause = 0.75;

    let stagger = duration + pause;
    let repeatDelay = stagger * (numberOfTargets - 1) + pause;
    let repeatDelay2 = stagger * (numberOfTargets - 1);

    function setTransactionPositioning() {
      let parentRect = document
        .getElementById("map-points")
        .getBoundingClientRect();
      pointIDs.map(val => {
        let transaction = document.getElementById(
          `transaction-${val.slice("1")}`
        );
        let pointEl = document.querySelector(`${val} .st1`);
        let rect = pointEl.getBoundingClientRect();
        transaction.style.top = rect["top"] - parentRect["top"] + "px";
        transaction.style.left = rect["left"] - parentRect["left"] + "px";
      });
    }

    function initTransactionAppearing() {
      const getPointId = el => el.id.split("-")[1];

      gsap.set("#map-points", { autoAlpha: 1 });
      tl.from(targets, {
        y: "-136%",
        opacity: 0,
        duration: duration,
        stagger: {
          onStart: function() {
            let pointId = getPointId(this.targets()[0]);
            let pst1 = document.getElementById(pointId).querySelector(".st1");
            pst1.classList.add("show");
            setTimeout(() => {
              pst1.classList.remove("show");
            }, stagger * 1000);

            TweenMax.fromTo(
              `#point-${pointId}`,
              { transformOrigin: "center center", opacity: 1, scale: 1.1 },
              {
                transformOrigin: "center center",
                opacity: 0,
                scale: 2.5,
                duration: stagger,
                repeat: -1,
                repeatDelay: repeatDelay2
              }
            );
          },
          onRepeat: function() {
            let pointId = getPointId(this.targets()[0]);
            let pst1 = document.getElementById(pointId).querySelector(".st1");
            pst1.classList.add("show");
            setTimeout(() => {
              pst1.classList.remove("show");
            }, stagger * 1000);
          },
          each: stagger,
          repeat: -1,
          repeatDelay: repeatDelay
        }
      }).to(
        targets,
        {
          y: "-106%",
          opacity: 0,
          duration: duration,
          stagger: {
            each: stagger,
            repeat: -1,
            repeatDelay: repeatDelay
          }
        },
        stagger
      );
    }

    initTransactionAppearing();
    setTransactionPositioning();
    window.addEventListener("resize", setTransactionPositioning);
  };

  pointTooltips();
}

window.addEventListener("load", () => {
  setMapPointsAnimation();
  phoneDropAnimation();
});

body.addEventListener("scroll", () => {
  if (document.body.clientWidth >= 768) {
    heroAnimationOnScroll();
  }
});
